<template>
    <div>
      <v-row justify="end" class="mb-2">
        <v-col cols="12" sm="2" md="2" lg="2" style="display: flex; justify-content: flex-end;">
        <v-btn color="edit" style="display: inline-block; margin: 0.1vh 0 0 0" @click="send('createNew')"><v-icon>add</v-icon>Add Contractor</v-btn>
      </v-col>
        <v-col cols="12" sm="6" md="6" lg="4">
          <el-input
            style="display: inline-block"
            id="search"
            suffix-icon="el-icon-search"
            v-model="search"
            clearable
            placeholder="Search"
          >               
          </el-input>
        </v-col>
      </v-row>
      <v-card flat class="scroll">
      <v-data-table
        height="72vh"
        id="table"
        style="cursor: pointer"
        :search="search"
        :items="contractors"
        single-expand
        show-expand
        :expanded.sync="expanded"
        :loading="loading"
        :headers="headers"
        item-key="name"
        :options="{
          page: 1,
          itemsPerPage: 20,
        }"
      >
      <template v-slot:[`item.icon`]="{ item }">
          <v-row justify="center">
            <v-avatar size="80"  v-if="item.icon">
              <v-img  height="50" :src="item.icon">
                <template slot="placeholder">
                  <v-icon style="opacity: 0.5" size="40">
                    business
                  </v-icon>
                </template>
              </v-img>
            </v-avatar>

          </v-row>
        </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="px-1" :colspan="headers.length" style="background-color:gray !important;">
          <v-card>
          <v-data-table
            style="margin: 4px 0 4px 0;"
            :headers="bookingHeaders"
            :items="item.bookings"
            id="bookingTable"
            hide-default-footer
          >
            <template v-slot:item.dateCreated="{ item }">
              <div>{{ formatTime(item.dateCreated).date }}</div>
            </template>
            <template v-slot:item.vesselOriginalEstimatedDateOfDeparture="{ item }">
              <div>{{ formatTime(item.vesselOriginalEstimatedDateOfDeparture).date }}</div>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip small :color="item.status=='COMPLETED' ? 'success' : item.status == 'PENDING' ? 'orange' : item.status == 'INPROGRESS' ? 'edit' : item.status == 'CANCELLED' ? 'danger' : 'gray' ">{{ item.status }}</v-chip>
            </template>
            
            <template v-slot:item.ppecbBookingItems="{ item }">
              <v-chip small color="primary">{{ item.ppecbBookingItems.length }}</v-chip>
            </template>
        </v-data-table>
      </v-card>
        </td>
      </template>
      </v-data-table>

    </v-card>
    <!-- <v-dialog persistent :value="" width="80vw">
    <v-card style="height: 80vh; width: 80vw; overflow-x: hidden;" >
     <CreateBooking @close="send('cancel')"/>
    </v-card>
  </v-dialog> -->
    </div>
</template>

<script>
import dateFormat from "dateformat";

export default {
//   setup() {
//     const {state, send} = useMachine(transportBookingMachine);
//     console.log(state)
//     return { state, send };
//   },
    props: [],
    components: {
    //   CreateBooking
    },
    data: () => ({
        clients: [],
        expanded: [],
        clientCounts: [],
        bookings: [],
        countriesArray: [],
        search: '',
        loading: false,
        relatedOrgs: [],
        contractors: [],

        headers: [
            {
                text: 'Logo',
                align: 'center',
                sortable: false,
                value: 'icon',
            },
            {
                text: 'Contractor',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: 'Bookings', value: 'bookingsCount'},
            {text: 'Booking Items', value: 'bookingItemsCount'},
            {text: 'FBO Code' , value: 'fboCode'},
            { text: '', value: 'data-table-expand', sortable: false },
            // {text: 'Date Submitted', value: 'dateSubmitted'},
            // {text: 'Bookings Completed', value: 'bookingsCompleted'},
            // {text: 'BookingItems Completed', value: 'bookingItemsCompleted'},
            // {text: 'Status', value: 'status'},
            // {text: 'Actions', value: 'actions'}
        ],
        bookingHeaders: [
        {text: 'Booking Items', value: 'ppecbBookingItems'},
        {text: 'Shipping Line',   value: 'shippingLine'},
        {text: 'Voyage Number',   value: 'voyageNumber'},
        {text: 'Voyage Number',   value: 'shippingLineVoyageNumber'},
        {text: 'Port of Loading', value: 'loadPort'},
        {text: 'Coordinator',     value: 'userName'},
        {text: 'Date Created',    value: 'dateCreated'},
        {text: 'EDD',             value: 'vesselOriginalEstimatedDateOfDeparture'},
        {text: 'Stack Dates',     value: 'stackDates'},
        {text: 'Vessel',          value: 'vesselName'},
        {text: 'Status',          value: 'status'},
        ]
    }),

    async created() {
      console.log(this.$store.state)
        this.bookings = await this.$API.getBookingsByClient(this.$store.state.currentOrg.id);
        this.contractors = await this.$API.getContractors(this.$store.state.currentOrg.id);
        this.send('assignBookings', {value: this.bookings})
        this.countriesArray = await this.$API.searchCountriesBasic({});
        this.send('assignCountries', {value: this.countriesArray})
        this.relatedOrgs = await this.$API.getCustomerRelationships('2507')
        console.log(this.relatedOrgs)
    },
    watch: {},
    methods: {

      formatTime(timestamp) {
      let time = dateFormat(timestamp, "hh:MM", true);
      let date = dateFormat(timestamp, "dd mmm yyyy");
      let dateTime = dateFormat(timestamp, "dd mmm yyyy hh:MM", true);
      return { time, date, dateTime };
    },

      // viewClient() {
      //   console.log(this.bookings)
      // }
        
    }
}

</script>

<style scoped>
#bookingTable table {
  background-color: #4a4a4a !important;
}

.itemClass {
  background-color: #4a4a4a !important;
  color: white !important;
}

</style>

